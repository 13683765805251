
import { Vue, prop } from "vue-class-component";

class Props {
  text!: string;
  buttonClass = prop<string>({ default: "" });
}

export default class Button extends Vue.with(Props) {
  private emitClicked() {
    setTimeout(() => this.$emit("clicked"), 100);
  }
}
