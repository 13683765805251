
import { Options, Vue } from "vue-class-component";
import router from "@/router";
import HoverIcon from "@/components/HoverIcon.vue";
import logoWhite from "@/assets/ico/header/logo-white.png";
import logoAccent from "@/assets/ico/header/logo-accent.png";

@Options({
  components: {
    HoverIcon,
  },
})
export default class Header extends Vue {
  private logoWhite = logoWhite;
  private logoAccent = logoAccent;

  private contentRoutes = router.options.routes.filter(
    (r) => r.path != "/" && r.path != "/:catchAll(.*)"
  );
}
