
import { Options, Vue } from "vue-class-component";

class Props {
  unhoveredIcon!: string;
  hoveredIcon!: string;
}

@Options({
  computed: {
    logo() {
      return this.logoHovered ? this.hoveredIcon : this.unhoveredIcon;
    },
  },
})
export default class HoverIcon extends Vue.with(Props) {
  private logoHovered = false;
}
