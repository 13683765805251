import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logoHovered = true)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.logoHovered = false)),
    src: _ctx.logo,
    alt: "Logo"
  }, null, 40, _hoisted_1))
}