import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { id: "sidebar-links" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "body2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HoverIcon = _resolveComponent("HoverIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", null, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
          return (_openBlock(), _createElementBlock("li", {
            key: link.title
          }, [
            _createElementVNode("a", {
              href: link.to,
              target: "_blank"
            }, [
              _createVNode(_component_HoverIcon, {
                unhoveredIcon: link.unhoveredIcon,
                hoveredIcon: link.hoveredIcon
              }, null, 8, ["unhoveredIcon", "hoveredIcon"])
            ], 8, _hoisted_2),
            _createElementVNode("span", _hoisted_3, _toDisplayString(link.title), 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}