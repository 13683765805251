import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_SidebarLinks = _resolveComponent("SidebarLinks")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component, route }) => [
          _createVNode(_Transition, {
            name: route.meta.transitionName,
            mode: route.meta.transitionName === '' ? 'out-in' : 'in-out'
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]),
            _: 2
          }, 1032, ["name", "mode"])
        ]),
        _: 1
      }),
      (_ctx.isContent)
        ? (_openBlock(), _createBlock(_component_SidebarLinks, { key: 0 }))
        : _createCommentVNode("", true)
    ])
  ], 64))
}