
import { Options, Vue } from "vue-class-component";
import router from "@/router";
import BackgroundImage from "@/components/BackgroundImage.vue";
import Button from "@/components/Button.vue";

@Options({
  components: {
    BackgroundImage,
    Button,
  },
})
export default class Home extends Vue {
  private goToAbout() {
    router.push("/about");
  }
}
