
import { Options, Vue } from "vue-class-component";
import Header from "@/components/layout/Header.vue";
import SidebarLinks from "@/components/layout/SidebarLinks.vue";

@Options({
  components: {
    Header,
    SidebarLinks,
  },
  computed: {
    isContent() {
      const routeName = this.$route.name;
      return !(routeName === "Home" || routeName === "NotFound");
    },
  },
})
export default class App extends Vue {}
